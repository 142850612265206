import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Portal,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { HavenGreenButton, HavenRedButton } from "components/buttons";
import React, { useEffect, useState } from "react";

import { Button } from "@chakra-ui/button";
import { FormGroup } from "./form";
import { StringInput } from "./inputs";
import { capitalizeAll } from "utils/text-transform";
import { useNavigate } from "react-router-dom";
import { usePost } from "../api/api-hooks";

/**
 * This component is used in the cards that represent associated entities such as device's dwelling, dwelling's company, etc. It displays basic information, provides the link to the details of that entity, and gives an ability to update the association to another entity or remove it entirely.
 */
export default function AssociatedEntity(props) {
    const {
        entityName,
        getEntityCall,
        fieldName,
        updateBody,
        onUpdate,
        gotoUrl = null,
        disableChange = false,
        removeEnabled = false,
    } = props;

    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure(); // this is for the modal
    const { call: updateAssociation } = usePost(props.updateUrl);
    const [entity, setEntity] = useState(props.entity); // current entity

    const form = useForm({ defaultValues: { ...entity } });
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = form;

    // Go to the entity details
    function onGoTo() {
        navigate(`/fleet/${gotoUrl}/${entity.id}`);
    }

    // When entity is updated, form must also be updated
    useEffect(() => {
        reset({ ...entity });
    }, [entity]);

    // Update association to another entity
    function onSubmit(data) {
        onClose(); // close the modal
        let body = { [fieldName]: data[fieldName] };
        if (props.updateBody) {
            body = { ...body, updateBody };
        }
        updateAssociation(body, () => {
            // after assignment is updated, refresh details by getting entity details again
            getEntityCall(data[fieldName]).then(entity => {
                // set entity to the refreshed entity
                setEntity(entity);
                // trigger optional onUpdate hook passed by the parent
                if (onUpdate) {
                    onUpdate();
                }
            });
        });
    }

    // Remove associated entity
    function onRemoveEntity() {
        updateAssociation({ [fieldName]: null }, () => {
            setEntity(null);
        });
    }

    // If no associated entity found, only allow to add a new association
    if (!entity) {
        return (
            <>
                <HStack mb={3}>
                    <Image src="/assets/cross.png" height="1.4rem" />
                    <Text color="#F96C6C">{entityName} not found</Text>
                </HStack>
                <HavenGreenButton label={`Add ${entityName}`} onClick={onOpen}></HavenGreenButton>

                <UpdateModal></UpdateModal>
            </>
        )
    }

    // If associated entity is found, display the details and ability to remove the entity
    return (
        <>
            <Box display="flex" flexDir="column">
                <FormGroup>
                    <StringInput form={form} field="id" disabled={true}></StringInput>
                    <StringInput form={form} field="name" disabled={true}></StringInput>
                </FormGroup>
                <Stack spacing={2} direction="row" mt="0.5rem">
                    {gotoUrl && <Button onClick={onGoTo}>Go to</Button>}
                    {!disableChange && <HavenGreenButton label={`Change ${entityName}`} onClick={onOpen}></HavenGreenButton>}
                    <Popover>
                        {removeEnabled && (
                            <PopoverTrigger>
                                <HavenRedButton label="REMOVE" onClick={null}></HavenRedButton>
                            </PopoverTrigger>
                        )}
                        <Portal>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverHeader>Are you sure you want to remove {entityName}?</PopoverHeader>
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <Button colorScheme="blue" onClick={onRemoveEntity}>
                                        Remove
                                    </Button>
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                </Stack>
            </Box>

            <UpdateModal></UpdateModal>
        </>
    )

    function UpdateModal() {
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Change {entityName}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="sand-form">
                                {/* Entity */}
                                <FormControl isInvalid={errors[fieldName]}>
                                    <FormLabel>Select new {entityName}</FormLabel>
                                    <Controller
                                        control={control}
                                        name={fieldName}
                                        defaultValue={null}
                                        rules={{ required: true }}
                                        render={({ field: { onChange } }) => (
                                            <>{React.cloneElement(props.children, { onChange: onChange })}</>
                                        )}
                                    />
                                    <FormErrorMessage>Select {entityName} from the list</FormErrorMessage>
                                </FormControl>
                            </div>

                            {/* Actions */}
                            <HStack mt={2} mb={2}>
                                {/* TODO: try to refactor this button since it pretty much duplicated <HavenGreenButton> */}
                                <Button
                                    fontSize="14px"
                                    border="1px"
                                    h={9}
                                    borderColor="#00C4C1"
                                    variant="outline"
                                    color="#00C4C1"
                                    type="submit"
                                >
                                    {capitalizeAll(`Change ${entityName}`)}
                                </Button>
                            </HStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }
}
