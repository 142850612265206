import { Box, Grid, GridItem, HStack, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import AssociatedEntity from "../../../components/associated-entity";
import Card from "../../../components/card";
import { CompanySearch } from "components/entity-search";
import DevicesList from "../../../components/devices-list";
import { DwellingAutomations } from "./dwelling-automations";
import { DwellingDetails } from "./dwelling-details";
import { DwellingProfiles } from "./dwelling-profile";
import { FlowCard } from "./flow";
import EntitiesTable from "../../../components/entities-list";
import Permissions from "../../../components/permissions";
import { PermissionsDescription } from "../../../components/info-popup";
import { getCompany } from "../../../api/api";
import { useEffect } from "react";
import SimulateOutdoorData from "./simulate-data";
import { SimulateTelemetry } from "./simulate-telemtry";
import { DeviceAlertForm } from "./device-alert-form";
import { GrafanaLinkDwelling } from "../../../components/grafana-links"

export default function Dwelling({ dwelling }) {
    const { details, profiles, company, equipments, permissions, devices, ecosense } = dwelling;
    const [refreshFlag, setRefreshFlag] = useState(false);

    const [isLargeScreen] = useMediaQuery("(min-width: 1480px)");
    const [isSmallScreen] = useMediaQuery("(max-width: 850px)");
    const [columnCount, setColumnCount] = useState(3);

    useEffect(() => {
        if (isSmallScreen) {
            setColumnCount(1);
        } else if (isLargeScreen) {
            setColumnCount(3);
        } else {
            setColumnCount(2);
        }
    }, [columnCount, isLargeScreen, isSmallScreen]);
    return (
        <>
            {<Grid templateColumns={`repeat(${columnCount}, 1fr)`} gap={4} width="100%">
                <DetailsCard details={details} />
                <ProfilesCard profiles={profiles} />
                <DevicesCard devices={devices} ecosense={ecosense} />
                {details?.data?.id && <CompanyCard company={company} dwellingId={+details?.data?.id} setRefreshFlag={setRefreshFlag} />}
                {details?.data?.id && <PermissionsCard permissions={permissions} dwellingId={+details?.data?.id} refreshFlag={refreshFlag} />}
                <EquipmentsCard equipments={equipments} />
                {(details?.data?.id && profiles?.data) && <AlertsCard devices={devices} dwellingId={+details?.data?.id} profiles={profiles.data} />}
                <SimulateDataCard postalCode={dwelling.details?.data?.postal_code} devices={devices} />
                {details?.data && devices?.data && <AutomationsCard details={dwelling.details} devices={devices} columnCount={columnCount} />}


            </Grid>}

        </>
    );
}

function DetailsCard({ details }) {
    return (
        <Card header="Details" error={details.error} loading={details.loading}>
            {details.data && <GrafanaLinkDwelling dwelling={details.data.id}></GrafanaLinkDwelling>}
            {details.data && <DwellingDetails details={details.data}></DwellingDetails>}
        </Card>
    );
}

function ProfilesCard({ profiles }) {
    return (
        <Card header="Profiles" error={profiles.error} loading={profiles.loading}>
            {profiles.data && <DwellingProfiles profiles={profiles.data}></DwellingProfiles>}
        </Card>
    );
}

function DevicesCard({ devices, ecosense }) {

    return (
        <Card header="Devices" error={devices.error} loading={devices.loading}>
            {devices.data && <DevicesList devices={devices.data} ecosense={ecosense.data}></DevicesList>}
        </Card>
    );
}

function CompanyCard({ company, dwellingId, setRefreshFlag }) {
    return (
        <Card header="Company" error={company.error} loading={company.loading} gotoUrl={`/fleet/companies/${company?.data?.id}`}>
            <AssociatedEntity
                entity={company.data}
                updateUrl={`dwelling/${dwellingId}`}
                entityName={"Company"}
                getEntityCall={getCompany}
                fieldName={"preferred_service_company_id"}
                onUpdate={() => setRefreshFlag(true)}
                removeEnabled={true}
            >
                <CompanySearch></CompanySearch>
            </AssociatedEntity>
        </Card>
    );
}

function AlertsCard({ devices, dwellingId, profiles }) {

    return (
        <Card
            header="Device Alerts"
            error={devices.error}
            loading={devices.loading}
        >
            <DeviceAlertForm devices={devices} dwellingId={dwellingId} profiles={profiles} />
        </Card>
    );
}

function PermissionsCard({ permissions, dwellingId, refreshFlag }) {
    return (
        <Card
            header="User Permissions"
            error={permissions.error}
            loading={permissions.loading}
            descriptionPopup={<PermissionsDescription />}
        >
            {permissions.data && (
                <Permissions
                    refreshFlag={refreshFlag}
                    permissions={permissions.data}
                    id={dwellingId}
                    parentEntity={"dwelling"}
                ></Permissions>
            )}
        </Card>
    );
}

function EquipmentsCard({ equipments }) {
    return (
        <Card
            header="Equipment"
            error={equipments.error}
            loading={equipments.loading}
            iconUrl="/assets/equipment.png"
        >
            {equipments.data && (
                <EntitiesTable list={equipments.data} openUrl="equipments" columns={["id", "name", "zone"]}></EntitiesTable>
            )}
        </Card>
    );
}

function SimulateDataCard({ devices, postalCode }) {
    return (
        <Card
            header="Simulate data"
            error={devices.error}
            loading={devices.loading}
        >
            <Stack>
                <Text>
                    Useful for creating test scenarios
                </Text>
                <HStack display='flex'>
                    <SimulateOutdoorData postalCode={postalCode} />
                    <SimulateTelemetry devices={devices.data} />
                </HStack>
            </Stack>
        </Card>
    );
}

function AutomationsCard({ details, devices, columnCount }) {
    if (!details.data) return null
    return (
        <Card
            header="Automations"
            error={details.error}
            loading={details.loading}
            gridColumn={['span 1', 'span 1', `span ${columnCount}`]}
        >
            <DwellingAutomations dwellingId={details.data.id} devices={devices} />
        </Card>
    );
}
